@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

/* Important: Making changes to this file could have an impact on shorthand articles */

.byline-thumbnail {
  picture,
  img {
    display: block;
    overflow: auto;
    border-radius: var(--byline--thumbnail--radius);
  }

  a:hover {
    opacity: 1;
  }
}
