@use './theme.tokens' as t;
@use 'assets/styles/utils/helper' as h;

.related-content {
  position: relative;
  z-index: 1;

  &.related-content__parent-no-img {
    padding-left: h.rem(20px);
    padding-right: h.rem(20px);
    margin-left: h.rem(20px);
  }

  .related-content__eyebrow {
    margin-top: 0;
    margin-bottom: 0;

    &-link {
      display: block;
      color: h.$think-red-40;
      font-size: h.rem(12px);
      font-weight: 400;
      font-family: var(--related-content-opinion-eyebrow-link-font);
      text-transform: uppercase;
      padding-left: h.rem(20px);
      margin-bottom: h.rem(8px);
    }
  }

  .related-content__headline {
    margin-top: 0;
    margin-bottom: 0;
    display: var(--related-content-headline-display);
    align-items: baseline;

    &-link {
      display: var(--related-content-headline-link-display);
      color: var(--related-content-headline-link-color);
      font-size: var(--related-content-headline-link-font-size);
      line-height: var(--related-content-headline-link-line-height);
      font-family: var(--related-content-headline-link-font);
      font-weight: var(--related-content-headline-link-font-weight);
      padding-left: var(--related-content-headline-link-padding-left);
      text-decoration-line: var(--related-content-headline-link-text-decoration-line);
      text-decoration-color: var(--related-content-headline-link-text-decoration-color);
      text-decoration-thickness: var(--related-content-headline-link-text-decoration-thickness);
      text-underline-offset: var(--related-content-headline-link-text-underline-offset);

      &:hover {
        color: var(--related-content-headline-link-hover-color);
      }
    }

    &--liveblog {
      font-family: var(--publico-txt);
      font-size: var(--text-14);
      line-height: var(--leading-120);
      color: h.$news-blue-40;

      &.active {
        color: h.$breaking-red;
      }
    }
  }

  .related-content__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-bottom: var(--related-content-list-margin-bottom);
  }

  .related-content__item {
    flex-basis: var(--two-item-flex-basis);
    flex-grow: 1;
    background-color: var(--related-content-item-bg-color);
    margin-bottom: var(--related-content-item-margin-bottom);
    margin-left: h.rem(40px);
    padding: var(--related-content-item-padding);

    &.related-content__item-featured {
      // 40px gutters
      @include h.breakpoint-m {
        flex-basis: t.$four-item-horizontal-flex-basis;
      }

      &.related-content__item-featured--one-up {
        @include h.breakpoint-m {
          margin-bottom: t.$offset-item-negative-mb;
        }

        &--rail-adjacent {
          @include h.breakpoint-l {
            flex-basis: t.$four-item-vertical-flex-basis;
          }

          @include h.breakpoint-x {
            flex-basis: t.$four-item-horizontal-flex-basis;
            margin-bottom: t.$offset-item-negative-mb;
          }
        }
      }

      &.related-content__item-featured--standard-lead {
        @include h.breakpoint-m {
          flex-basis: t.$four-item-vertical-flex-basis;
        }

        @include h.breakpoint-l {
          flex-basis: t.$four-item-horizontal-flex-basis;
          margin-bottom: t.$offset-item-negative-mb;
        }

        &--rail-adjacent {
          @include h.breakpoint-m {
            flex-basis: t.$four-item-vertical-flex-basis;
          }

          @include h.breakpoint-x {
            flex-basis: t.$four-item-horizontal-flex-basis;
            margin-bottom: t.$offset-item-negative-mb;
          }
        }
      }

      &.related-content__item-featured--straight-up {
        @include h.breakpoint-l {
          flex-basis: t.$four-item-vertical-flex-basis;
        }

        &--rail-adjacent {
          @include h.breakpoint-l {
            flex-basis: t.$four-item-vertical-flex-basis;
          }

          @include h.breakpoint-x {
            flex-basis: t.$four-item-horizontal-flex-basis;
            margin-bottom: t.$offset-item-negative-mb;
          }
        }
      }
    }

    &.related-content__item--lead-section-front {
      @include h.breakpoint-x {
        margin-bottom: t.$offset-item-negative-mb;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 50%;
      height: 1px;
      border-width: 0;
      border-top-style: solid;
      border-top-width: var(--related-content-item-before-border-width);
      border-color: var(--grey-40);
      margin-bottom: var(--related-content-item-before-margin-bottom);
      max-width: var(--related-content-item-before-width);

      .feature-lead__related-links & {
        border-top-width: 0;
        margin-bottom: h.rem(14px);
      }
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 1;
    }

    &:nth-child(4) {
      z-index: 0;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    &:nth-child(1) {
      z-index: 3;

      .feature-lead__related-links & {
        padding-top: 0;
      }

      &::before {
        .feature-lead__related-links & {
          border-top-width: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .related-content__icon {
    align-items: flex-start;
    background: transparent;
    bottom: auto;
    color: var(--related-content-icon-color);
    font-size: 8px;
    justify-content: flex-start;
    left: auto;
    margin-top: 6px;
    position: absolute;

    &.related-content__icon-opinion {
      color: h.$think-red-40;
      margin-top: h.rem(4px);
    }

    &.related-content__icon-gallery {
      font-size: 10px;
    }

    &.related-content__icon-liveblog {
      &.active {
        color: h.$breaking-red;
      }
    }

    body.today &.icon-dot {
      display: none;
    }
  }
}
