@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.tease-category__eyebrow {
  color: var(--tease-category-eyebrow-color);
  background-color: var(--tease-category-eyebrow-background-color);
  font-family: var(--tease-category-eyebrow-font-family);
  font-weight: var(--tease-category-eyebrow-font-weight);
  font-size: var(--tease-category-eyebrow-font-size);
  height: h.rem(30px);
  letter-spacing: var(--tease-category-eyebrow-letter-spacing);
  line-height: 1;
  min-width: h.rem(130px);
  margin: h.rem(-30px) 0 0;
  position: relative;
  text-transform: uppercase;
  float: left;
  display: flex;
  justify-content: flex-start;
  z-index: 2;

  &--default-spacing {
    padding: 1rem 1.25rem .25rem;

    @include h.breakpoint-m {
      padding-left: 1rem;
    }
  }

  &--icon-spacing {
    padding-right: 3rem;
  }

  & span:hover {
    opacity: var(--opacity-70);
  }

  @include h.breakpoint-x {
    height: h.rem(32px);
    margin: h.rem(-32px) 0 0;
  }

  @each $vertical, $color in t.$tease-category-modulealternate-color-map {
    .#{$vertical}-module & {
      color: $color;
    }
  }

  &.sponsored {
    background: var(--grey-70);
    color: var(--white);
  }

  .tease-card--no-main-image & {
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: absolute;
    top: h.rem(20px);
    left: h.rem(20px);
    height: auto;
    float: none;
    z-index: 3;
  }

  .news &.tease-category__eyebrow--large {
    min-width: h.rem(160px);
  }
}

.tease-category__typeIcon {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
}

.icon-gallery {
  font-size: h.rem(14px);

  @include h.breakpoint-m {
    font-size: h.rem(8px);
  }
}

.tease-card--no-main-image:not(.tease-card--has-featured-author) .tease-category__eyebrow {
  margin-bottom: auto;
}
