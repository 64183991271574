@use 'assets/styles/utils/helper' as h;

.title {
  @include h.responsive-variations(line-height, (s: 1.25, m: 1.5));
  @include h.responsive-variations(margin, (s: 0 0 h.rem(22px), m: 0 0 h.rem(14px)));
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: h.rem(24px);
  color: var(--grey-70);

  @include h.breakpoint-m {
    padding: 0;
  }
}
