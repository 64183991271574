@use 'assets/styles/utils/helper' as h;

$multi-up-tease-card-two-up-featured-author-line-heights: (m: h.rem(30px)) !default;
$multi-up-tease-card-three-up-featured-author-line-height: h.rem(25px) !default;
$multi-up-tease-card-three-up-main-featured-author-line-heights: (m: h.rem(20px), l: h.rem(30px), x: h.rem(40px)) !default;
$multi-up-tease-card-four-up-featured-author-line-heights: (m: h.rem(20px), l: h.rem(25px)) !default;

:root {
  --multi-up-has-title-background-color: var(--white);
  --multi-up--articles--background-color: var(--white);
  --multi-up-tease-card-two-up-featured-author-font-size: #{h.rem(26px)};
  --multi-up-tease-card-two-up-three-up-line-height: 100%;
  --multi-up-tease-card-four-up-line-height: 100%;
  --multi-up-tease-card-three-up-no-image-title-line-height: 100%;
  --multi-up-tease-card-three-up-featured-author-font-size: #{h.rem(20px)};
  --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(24px)};
  --multi-up-article-margin-bottom: var(--package-spacing);
  --multi-up-two-up-flex-basis: calc(#{50%} - 1px);

  @include h.breakpoint-m {
    --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(28px)};
  }

  @include h.breakpoint-l {
    --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(32px)};
  }
}

body.today {
  --multi-up-has-title-background-color: transparent;
  --multi-up-tease-card-two-up-featured-author-font-size: #{h.rem(23.4px)};
  --multi-up-tease-card-two-up-three-up-line-height: 150%;
  --multi-up-tease-card-four-up-line-height: 125%;
  --multi-up-tease-card-three-up-featured-author-font-size: #{h.rem(18px)};
  --multi-up--articles--background-color: transparent;
  --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(18px)};
  --multi-up-article-margin-bottom: #{h.rem(32px)};
  --multi-up-two-up-flex-basis: 44.5%;

  @include h.breakpoint-m {
    --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(16px)};
  }
}

body.think {
  --multi-up-tease-card-two-up-featured-author-font-size: #{h.rem(23.4px)};
  --multi-up-tease-card-two-up-three-up-line-height: 125%;
  --multi-up-tease-card-four-up-line-height: 125%;
  --multi-up-tease-card-three-up-featured-author-font-size: #{h.rem(18px)};
  --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(24px)};
}

body.select {
  --multi-up-tease-card-two-up-three-up-line-height: 120%;
  --multi-up-tease-card-four-up-line-height: 120%;
  --multi-up-tease-card-three-up-no-image-title-line-height: 120%;
  --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(22px)};

  @include h.breakpoint-m {
    --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(18px)};
  }

  @include h.breakpoint-l {
    --multi-up-tease-card-two-up-three-up-font-size: #{h.rem(24px)};
  }
}

body.eonline {
  .pkg-theme--dark {
    --multi-up--articles--background-color: var(--grey-70);
    --multi-up-has-title-background-color: var(--grey-70);
  }
}

body.sponsoredcontent {
  --multi-up--articles--background-color: #ddd;
}
