@use 'assets/styles/utils/helper' as h;

:global(.today) .adWrapper {
  @include h.breakpoint-s-only {
    max-width: calc(100vw - 20px);
    position: relative;
  }
}

// optimistic classes to try to get Taboola native ads to also follow the link hover treatment
// Taboola's markup is very brittle :-/
.nativeAdContainer {
  :global(#taboola-homepage-text-link---bento),
  :global(#taboola-homepage-thumbnail---bento) {
    :global(span.branding),
    :global(span.video-label.video-title),
    :global(a.trc_desktop_disclosure_link.trc_attribution_position_bottom) {
      &:hover {
        opacity: var(--default-hover-opacity);
      }

      // stylelint-disable no-descending-specificity
      &:active {
        opacity: var(--default-active-opacity);
      }
    }
  }
}
