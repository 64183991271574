@use 'assets/styles/utils/helper' as h;

// The flex-basis numbers are ~*magic*~ numbers to some extent.
// For 2 item support, text containers with the dek and headline that are 459px+,
// we want to sit side-by-side or horizontally, otherwise they stack.
// For 4 item support, containers that 699px+ (originally 700px,
// but StraightUps at 1240px in a rail are just one px short) will want RC to sit horizontally.
// TLDR: 459 / 2 = 229.5px, 699 / 4 - 40px (gutters) = 134px
$four-item-horizontal-flex-basis: 134px !default;
$four-item-vertical-flex-basis: 100% !default;
$offset-item-negative-mb: h.rem(17px) !default;

:root {
  --two-item-flex-basis: 229.5px;
  --related-content-opinion-eyebrow-link-font: var(--founders-mono);
  --related-content-headline-display: block;
  --related-content-headline-link-display: block;
  --related-content-headline-link-color: var(--grey-70);
  --related-content-headline-link-hover-color: var(--grey-70);
  --related-content-headline-link-font: var(--default-headline-font-family);
  --related-content-headline-link-line-height: var(--leading-100);
  --related-content-headline-link-font-weight: var(--normal);
  --related-content-headline-link-padding-left: #{h.rem(20px)};
  --related-content-icon-color: var(--default-color);
  --related-content-item-bg-color: var(--white);
  --related-content-list-margin-bottom: 0;
  --related-content-item-padding: #{h.rem(16px 0 0 0)};
  --related-content-item-before-width: 229.5px;
  --related-content-item-before-border-width: 1px;
  --related-content-item-before-margin-bottom: #{h.rem(16px)};
  --related-content-headline-link-font-size: 1rem;
  --related-content-item-margin-bottom: #{h.rem(-17px)};
  --related-content-item-first-child-padding: #{h.rem(16px 0 0 0)};
  --related-content-item-first-child-before-margin-bottom: #{h.rem(16px)};
  --related-content-item-first-child-before-border-width: 1px;
}

body.today {
  --related-content-headline-link-color: var(--grey-70);
  --related-content-headline-link-hover-color: var(--primary-color);
  --related-content-headline-link-line-height: var(--leading-150);
  --related-content-item-bg-color: var(--white);
  --two-item-flex-basis: 100%;
  --related-content-headline-link-font: var(--secondary-font);
  --related-content-headline-link-padding-left: #{h.rem(13px)};
  --related-content-item-padding: 0;
  --related-content-item-before-width: 117px;
  --related-content-item-before-border-width: 0;
  --related-content-headline-link-font-size: #{h.rem(16px)};
  --related-content-item-before-margin-bottom: #{h.rem(14px)};
  --related-content-item-margin-bottom: 0;
  --related-content-headline-link-text-decoration-line: underline;
  --related-content-headline-link-text-decoration-color: var(--accent-orange);
  --related-content-headline-link-text-decoration-thickness: 2px;
  --related-content-headline-link-text-underline-offset: 4px;

  .related-content .icon-dot + .related-content__headline {
    --related-content-headline-link-padding-left: 0;
  }

  .related-content .related-content__item:first-child {
    --related-content-item-padding: #{h.rem(30px 0 0 0)};
    --related-content-item-before-margin-bottom: #{h.rem(30px)};
    --related-content-item-before-border-width: 2px;
  }

  .featureLead {
    --related-content-list-margin-bottom: 20px;
    --related-content-headline-link-display: inline;

    @include h.breakpoint-m {
      --related-content-headline-display: flex;
    }
  }

  @include h.breakpoint-m {
    --related-content-item-before-width: 96px;
    --related-content-headline-link-font-size: #{h.rem(14px)};
  }
}

body.think {
  --related-content-headline-link-line-height: var(--leading-125);
}

body.telemundo,
body.entretenimiento,
body.shows {
  --related-content-headline-link-color: var(--blue-60);
  --related-content-headline-link-hover-color: var(--blue-60);
}

body.select {
  --related-content-opinion-eyebrow-link-font: var(--lato);
  --related-content-headline-link-color: #2a2a2a;
  --related-content-headline-link-hover-color: #2a2a2a;
  --related-content-headline-link-font: var(--lato);
  --related-content-headline-link-line-height: var(--leading-150);
  --related-content-headline-link-font-weight: 900;
  --related-content-icon-color: var(--blue-60);
  --related-content-item-bg-color: var(--white);
  --related-content-headline-link-font-size: #{h.rem(14px)};
}
