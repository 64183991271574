@use 'assets/styles/utils/helper' as h;
@use 'theme.tokens' as t;
@use 'sass:math';

body.today {
  .pkg.multiUp {
    position: relative;

    &::before {
      top: 0;
      background: var(--grey-20);
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: h.rem(215px);
      left: 50%;
      transform: translateX(-50%) rotate(2.3deg) skew(2.3deg);

      @include h.breakpoint-m {
        height: h.rem(250px);
      }
    }

    &.alternateStripe::before {
      transform: translateX(-50%) rotate(-2.3deg) skew(-2.3deg);
    }

    &.multi-up__has-title::before {
      @include h.responsive-variations(top, (s: h.rem(38px), m: h.rem(41px), l: h.rem(65px)));
    }
  }

  .teaseRow--noButton + .multi-up__two-up {
    margin-top: calc(var(--package-spacing) + 20px);
  }
}

.multi-up__content {
  .multi-up__has-title & {
    position: relative;
  }
}

.multi-up__articles {
  position: relative;
  z-index: 2;
  background-color: var(--multi-up--articles--background-color);

  @include h.breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  .multi-up__four-up & {
    @include h.breakpoint-m {
      flex-wrap: wrap;
    }
  }

  .multi-up__has-title & {
    background-color: var(--multi-up-has-title-background-color);
  }

  .msnbc &.multi-up__accent-line {
    border-top: 5px solid #ffbe03;
  }
}

.multi-up__article {
  margin-bottom: var(--multi-up-article-margin-bottom);

  @include h.breakpoint-m {
    margin-left: 0;
  }

  .multi-up__has-title & {
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .multi-up__two-up & {
    background: none;

    @include h.breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: var(--multi-up-two-up-flex-basis);
      margin-bottom: 0;
    }
  }

  .multi-up__three_up & {
    @include h.breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{math.div(1, 3) * 100%} - 1.33px);
      margin-bottom: 0;
    }

    @include h.breakpoint-l {
      flex-basis: calc(#{25%} - 1.33px);

      &:first-of-type {
        flex-basis: calc(#{50%} - 1.33px);
      }
    }
  }

  .multi-up__four-up & {
    @include h.breakpoint-m {
      &:first-of-type {
        margin-right: auto;
      }

      &:last-of-type {
        margin-left: auto;
      }
      flex-basis: calc(#{50%} - 1px);

      &:nth-of-type(n+3) {
        margin-bottom: 0;
      }
    }

    @include h.breakpoint-l {
      flex-basis: calc(#{25%} - 1.5px);

      &:first-of-type {
        margin-right: unset;
      }

      &:last-of-type {
        margin-left: unset;
      }
      margin-bottom: 0;
    }
  }
}

.multi-up__no-main-image,
.multi-up__has-featured-author {
  background-color: transparent;
}

// Tease Card threeUp
.multi-up__tease-card--three-up {
  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(18px), l: h.rem(20px), x: h.rem(24px)));

    &--today {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(14px), l: h.rem(18px)));
    }

    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(18px), l: h.rem(18px), x: h.rem(18px)));
    }

    &--select {
      @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(18px), l: h.rem(20px), x: h.rem(22px)));
    }
    line-height: var(--multi-up-tease-card-two-up-three-up-line-height);

    .tease-card__headline--deepLink-episode {
      font-size: h.rem(20px);

      @include h.breakpoint-m {
        font-size: h.rem(16px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-l {
        font-size: h.rem(18px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-x {
        font-size: h.rem(20px);
        line-height: var(--leading-125);
      }
    }
  }

  .tease-card__featured-author {
    font-size: var(--multi-up-tease-card-three-up-featured-author-font-size);
    line-height: t.$multi-up-tease-card-three-up-featured-author-line-height;
  }

  .tease-card--no-main-image {
    font-size: h.rem(22px);
    line-height: h.rem(30px);

    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(28px), l: h.rem(24px), x: h.rem(32px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(20px), l: h.rem(24px)));
      }

      &--select {
        @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(24px), l: h.rem(24px), x: h.rem(28px)));
      }
      line-height: var(--multi-up-tease-card-three-up-no-image-title-line-height);
    }
  }
}

// stylelint-disable no-descending-specificity
// Tease Card TwoUp
.multi-up__tease-card--two-up {
  .tease-card__title {
    font-size: var(--multi-up-tease-card-two-up-three-up-font-size);
    line-height: var(--multi-up-tease-card-two-up-three-up-line-height);

    .tease-card__headline--deepLink-episode {
      font-size: h.rem(20px);

      @include h.breakpoint-m {
        font-size: h.rem(24px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-l {
        font-size: h.rem(28px);
        line-height: var(--leading-125);
      }
    }
  }

  .tease-card__featured-author {
    @include h.responsive-variations(line-height, t.$multi-up-tease-card-two-up-featured-author-line-heights);

    @include h.breakpoint-m {
      font-size: var(--multi-up-tease-card-two-up-featured-author-font-size);
    }
  }

  .tease-card--no-main-image {
    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(32px), m: h.rem(40px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(32px)));
      }

      &--select {
        @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(24px), l: h.rem(32px), x: h.rem(36px)));
      }
    }
  }
}

// Tease Card fourUp
.multi-up__tease-card--four-up {
  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), l: h.rem(18px), x: h.rem(24px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(24px), l: h.rem(16px), x: h.rem(18px)));
    }
    line-height: var(--multi-up-tease-card-four-up-line-height);

    .tease-card__headline--deepLink-episode {
      font-size: h.rem(20px);

      @include h.breakpoint-m {
        font-size: h.rem(20px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-l {
        font-size: h.rem(16px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-x {
        font-size: h.rem(20px);
        line-height: var(--leading-100);
      }
    }
  }

  .tease-card__featured-author {
    @include h.responsive-variations(font-size, (m: h.rem(26px), l: h.rem(20px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (m: h.rem(23.4px), l: h.rem(18px)));
    }

    @include h.responsive-variations(line-height, t.$multi-up-tease-card-four-up-featured-author-line-heights);
  }
}

// Tease Card threeUpMain
.multi-up__tease-card--three-up-main {
  .tease-card__title {
    @include h.responsive-variations(font-size, (s: h.rem(24px), m: h.rem(18px), l: h.rem(32px), x: h.rem(40px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (s: h.rem(18px), m: h.rem(14px), l: h.rem(24px)));
    }

    &--select {
      @include h.responsive-variations(font-size, (s: h.rem(22px), m: h.rem(18px), l: h.rem(24px), x: h.rem(24px)));
    }
    line-height: var(--multi-up-tease-card-two-up-three-up-line-height);

    .tease-card__headline--deepLink-episode {
      font-size: h.rem(20px);

      @include h.breakpoint-m {
        font-size: h.rem(16px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-l {
        font-size: h.rem(28px);
        line-height: var(--leading-100);
      }

      @include h.breakpoint-x {
        font-size: h.rem(32px);
        line-height: var(--leading-100);
      }
    }
  }

  .tease-card__featured-author {
    @include h.responsive-variations(font-size, (m: h.rem(14px), l: h.rem(26px), x: h.rem(32px)));

    &--today,
    &--think {
      @include h.responsive-variations(font-size, (m: h.rem(12.6px), l: h.rem(23.4px), x: h.rem(28.8px)));
    }

    @include h.responsive-variations(line-height, t.$multi-up-tease-card-three-up-main-featured-author-line-heights);
  }

  .tease-card--no-main-image {
    @include h.breakpoint-l {
      font-size: 50px;
      line-height: 50px;
    }

    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(28px), l: h.rem(40px), x: h.rem(48px)));

      &--today {
        @include h.responsive-variations(font-size, (s: h.rem(20px), l: h.rem(32px)));
      }
      line-height: 100%;
    }
  }
}

// stylelint-disable-next-line no-duplicate-selectors
.multi-up__tease-card--four-up {
  .tease-card--no-main-image {
    .tease-card__title {
      @include h.responsive-variations(font-size, (s: h.rem(28px), m: h.rem(40px), l: h.rem(24px), x: h.rem(32px)));

      &--today,
      &--think {
        @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px), l: h.rem(20px), x: h.rem(24px)));
      }
      line-height: var(--multi-up-tease-card-four-up-line-height);
    }
  }
}

.multiUp {
  .pkg-bottom-border {
    @include h.breakpoint-s-only {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
