@use 'assets/styles/utils/helper' as h;

$tease-card-featured-author-line-heights: (
  s: h.rem(25px),
  m: h.rem(40px),
  l: h.rem(30px),
  x: h.rem(40px),
) !default;
$tease-card-title-line-heights: (
  s: h.rem(25px),
  m: h.rem(40px),
  l: h.rem(30px),
  x: h.rem(40px),
) !default;

:root {
  --tease-card-info-background-color: var(--white);
  --tease-card-info-padding: #{h.rem(12px 16px 0)};
  --tease-card-no-art-background-color: #f1f0ed;
  --tease-card-no-art-line-height: 22px;
  --tease-card--no-main-image-height: auto;
  --tease-card-featured-author-font-family: var(--publico-hed);
  --tease-card-featured-author-color: var(--default-color);
  --tease-card-title-color: var(--grey-70);
  --tease-card-title-deeplink-episode-color: var(--default-color);
  --tease-card-title-font-family: var(--founders-cond);
  --tease-card-title-font-weight: normal;
  --tease-card-title-headline-color: inherit;
  --tease-card-art-title-line-height: var(--leading-100);
  --tease-card-byline-color: var(--grey-60);
  --tease-card-byline-margin: #{h.rem(12px) 0 0 0};
  --tease-card-byline-font-size: #{h.rem(12px)};
  --tease-card-byline-line-height: var(--leading-150);
  --tease-card-byline-letter-spacing: -0.04em;
  --tease-card-byline-font-family: var(--founders-mono);
  --tease-card-author-text-transform: none;
  --tease-card-author-font-weight: normal;
  --tease-card-eyebrow-letter-spacing: inherit;
  --tease-card-dek-color: rgba(var(--grey-70-rgb), 0.7);
  --tease-card-live-text-font-family: var(--founders-cond);
  --tease-card-paid-content-sponsor-font-family: var(--founders-mono);
  --tease-card-quote-color: var(--default-color);
  --tease-card-outer-gutter: 20px;
  --tease-card-featured-author-font-size: #{h.rem(20px)};
  --tease-card-title-font-size: #{h.rem(24px)};
  --tease-card-title--hover--opacity: var(--opacity-70);
  --tease-card-title--link--hover-border: none;
  --tease-card-picture--hover-transform: none;
  --tease-card-content-inner-display: block;
  --tease-card-tease-row-related-margin-top: 20px;
  --tease-card-no-image-padding: #{h.rem(16px 20px 40px 20px)};
  --tease-card-no-image-margin: #{h.rem(20px 0 0 20px)};
  --tease-card--sponsor-name-font-family: var(--founders-cond);

  @include h.breakpoint-m {
    --tease-card-featured-author-font-size: #{h.rem(32px)};
    --tease-card-title-font-size: #{h.rem(40px)};
  }

  @include h.breakpoint-l {
    --tease-card-featured-author-font-size: #{h.rem(26px)};
    --tease-card-title-font-size: #{h.rem(30px)};
  }

  @include h.breakpoint-x {
    --tease-card-featured-author-font-size: #{h.rem(32px)};
    --tease-card-title-font-size: #{h.rem(40px)};
  }
}

.straight-up {
  @include h.breakpoint-l {
    --tease-card-content-inner-display: flex;
  }
}

body.today {
  --tease-card-info-background-color: transparent;
  --tease-card-art-title-line-height: var(--leading-125);
  --tease-card-featured-author-font-family: var(--secondary-font);
  --tease-card-title-font-family: var(--secondary-font);
  --tease-card-info-padding: #{h.rem(26px 20px 0)};
  --tease-card-featured-author-font-size: #{h.rem(18px)};
  --tease-card-title-font-size: #{h.rem(18px)};
  --tease-card-title-headline-color: var(--grey-70);
  --tease-card-no-image-padding: #{h.rem(16px 0 40px 0)};
  --tease-card-no-art-background-color: transparent;
  --tease-card-title-color: var(--grey-70);
  --tease-card-title--hover--opacity: 1;
  --tease-card-title--link--hover-border: 2px solid var(--accent-blue);
  --tease-card-picture--hover-transform: rotate(1deg);
  --tease-card-byline-font-family: var(--secondary-font);
  --tease-card-byline-color: var(--grey-70);
  --tease-card-author-font-weight: bold;

  @include h.breakpoint-m {
    --tease-card-featured-author-font-size: #{h.rem(30px)};
    --tease-card-title-font-size: #{h.rem(24px)};
    --tease-card-no-image-margin: #{h.rem(20px 0 0 0)};
    --tease-card-info-padding: #{h.rem(24px 0 0)};
    --tease-card-no-image-padding: #{h.rem(16px 20px 40px 0)};
  }

  @include h.breakpoint-l {
    --tease-card-featured-author-font-size: #{h.rem(22.5px)};
    --tease-card-title-font-size: #{h.rem(24px)};
    --tease-card-tease-row-related-margin-top: 20px;
  }

  @include h.breakpoint-x {
    --tease-card-featured-author-font-size: #{h.rem(30px)};
    --tease-card-title-font-size: #{h.rem(24px)};
  }

  .straight-up {
    --tease-card-title-font-family: var(--primary-font);
  }

  .tease-card__has-image-credit {
    --tease-card-tease-row-related-margin-top: 35px;
  }
}

body.think {
  --tease-card-featured-author-font-family: var(--founders-mono);
  --tease-card-art-title-line-height: var(--leading-125);
  --tease-card-title-font-family: var(--publico-hed);
  --tease-card-featured-author-font-size: #{h.rem(18px)};
  --tease-card-title-font-size: #{h.rem(18px)};

  @include h.breakpoint-m {
    --tease-card-featured-author-font-size: #{h.rem(28.8px)};
    --tease-card-title-font-size: #{h.rem(30px)};
  }

  @include h.breakpoint-l {
    --tease-card-featured-author-font-size: #{h.rem(23.4px)};
    --tease-card-title-font-size: #{h.rem(22.5px)};
  }

  @include h.breakpoint-x {
    --tease-card-featured-author-font-size: #{h.rem(28.8px)};
    --tease-card-title-font-size: #{h.rem(30px)};
  }
}

body.telemundo,
body.entretenimiento,
body.shows {
  --tease-card-title-color: var(--blue-60);
  --tease-card-title-deeplink-episode-color: var(--red-50);
  --tease-card-dek-color: var(--grey-60);
}

body.deportes,
body.noticias {
  --tease-card-dek-color: var(--grey-60);
}

body.select {
  --tease-card-featured-author-font-family: var(--lato);
  --tease-card-art-title-line-height: 120%;
  --tease-card--no-main-image-height: 100%;
  --tease-card-title-color: #2a2a2a;
  --tease-card-title-font-family: var(--lato);
  --tease-card-title-font-weight: 900;
  --tease-card-byline-font-family: var(--lato);
  --tease-card-byline-color: var(--grey-60);
  --tease-card-byline-margin: 12px 0 0;
  --tease-card-byline-font-size: 12px;
  --tease-card-byline-line-height: var(--leading-150);
  --tease-card-byline-letter-spacing: 0;
  --tease-card-author-text-transform: uppercase;
  --tease-card-eyebrow-letter-spacing: 0;
  --tease-card-no-art-background-color: #f2f2f2;
  --tease-card-no-art-line-height: 120%;
  --tease-card-featured-author-font-size: #{h.rem(18px)};
  --tease-card-title-font-size: #{h.rem(22px)};

  @include h.breakpoint-m {
    --tease-card-featured-author-font-size: #{h.rem(28.8px)};
    --tease-card-title-font-size: #{h.rem(24px)};
  }

  @include h.breakpoint-l {
    --tease-card-featured-author-font-size: #{h.rem(23.4px)};
    --tease-card-title-font-size: #{h.rem(22px)};
  }

  @include h.breakpoint-x {
    --tease-card-featured-author-font-size: #{h.rem(28.8px)};
    --tease-card-title-font-size: #{h.rem(24px)};
  }
}

body.eonline {
  .pkg-theme--dark {
    --tease-card-info-background-color: var(--grey-70);
    --tease-card-title-color: var(--white);
  }
}
