@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.timestamp {
  display: var(--ts--display);
  font-family: var(--ts--font-family);
  font-size: var(--ts--font-size);
  font-weight: var(--ts--font-weight);
  line-height: var(--leading-100);
  letter-spacing: var(--ts--letter-spacing, normal);
  white-space: nowrap;
  color: var(--ts--text-color, var(--grey-70));
  margin-right: var(--ts--margin-right);
}

.justNow {
  color: rgb(203, 1, 1);
}

.chatStyleTimeStamp {
  color: var(--chatStyle-ts--text-color, var(--grey-60));
  font-family: var(--chatStyle-ts--font-family);
  font-size: var(--chatStyle-ts--font-size);
  font-weight: var(--chatyStyle-ts--font-weight);
  line-height: 120%;
  letter-spacing: var(--chatyStyle-ts--letter-spacing, normal);
}
