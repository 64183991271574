@use 'assets/styles/utils/helper' as h;

$tease-category-modulealternate-color-map: (
  better: h.$better-teal-30,
  news: h.$news-blue-40,
  think: h.$think-red-40,
  mach: h.$mach-purple-50,
  globalcitizen: h.$global-red-40,
  msnbc: h.$news-blue-40,
  noticias: h.$noticias-red-50,
) !default;

:root {
  --tease-category-eyebrow-color: var(--default-color);
  --tease-category-eyebrow-background-color: var(--white);
  --tease-category-eyebrow-font-family: var(--founders-mono);
  --tease-category-eyebrow-font-weight: 100;
  --tease-category-eyebrow-font-size: 0.75rem;
  --tease-category-eyebrow-letter-spacing: normal;
}

.today {
  --tease-category-eyebrow-background-color: var(--neutral-10);
}

.think {
  --tease-category-eyebrow-background-color: var(--white);
}

.globalcitizen {
  --tease-category-eyebrow-color: var(--red-40);
}

.select {
  --tease-category-eyebrow-font-family: var(--lato);
  --tease-category-eyebrow-color: var(--blue-60);
  --tease-category-eyebrow-font-weight: 400;
  --tease-category-eyebrow-font-size: #{h.rem(12px)};
  --tease-category-eyebrow-letter-spacing: -0.5px;
}
