/* stylelint-disable ramen/import-helper-file */

.dek {
  --dek--color: var(--grey-70);
  --dek--font-family: var(--publico-txt);

  :global(body.select) & {
    --dek--color: #2a2a2a;
    --dek--font-family: var(--lato);
  }

  :global(body.noticias) &,
  :global(body.deportes) &,
  :global(body.telemundo) &,
  :global(body.entretenimiento) &,
  :global(body.shows) & {
    --dek--color: var(--grey-60);
  }
}
