@use 'assets/styles/utils/helper' as h;
@use './theme.tokens';

.dek {
  color: var(--dek--color);
  font-size: 14px;
  font-family: var(--dek--font-family);
  line-height: 1.5;
  margin-bottom: 0;

  @include h.breakpoint-m {
    flex: 1 1 50%;
  }

  @include h.breakpoint-l {
    padding-right: h.rem(20px);
  }

  @include h.breakpoint-x {
    padding-right: h.rem(0);
  }

  :global(body.today .straight-up) & {
    font-family: var(--secondary-font);
    margin-top: h.rem(10px);

    @include h.breakpoint-m {
      font-size: h.rem(16px);
    }
  }
}
