@use 'assets/styles/utils/helper' as h;

.teaseRow {
  @include h.responsive-variations(
    padding,
    (
      s: 0 h.$outer-gutter,
      m: 0,
    )
  );

  &.searchWordGame {
    padding: 0;
  }
}

.wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;

  @include h.breakpoint-m {
    flex-direction: row;
  }
}

.contentItem {
  @include h.responsive-variations(
    flex-direction,
    (
      m: column,
      x: row,
    )
  );

  .searchWordGame & {
    flex-direction: row;
  }

  &.noImg {
    flex-direction: row;
  }
  display: flex;
  margin-bottom: h.rem(28px);

  &:last-child {
    margin-bottom: 0;
  }

  @include h.breakpoint-m {
    &.lastRow {
      margin-bottom: 0;
    }
  }
}

.teaseWrapper {
  @include h.responsive-variations(
    width,
    (
      s: h.rem(135px),
      m: h.rem(90px),
      l: h.rem(120px),
      x: h.rem(90px),
    )
  );
  @include h.responsive-variations(
    flex-basis,
    (
      s: h.rem(135px),
      m: h.rem(90px),
      l: h.rem(120px),
      x: h.rem(90px),
    )
  );

  .searchWordGame & {
    width: h.rem(77px);
    flex-basis: h.rem(77px);
  }
  align-self: flex-start;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  picture:hover {
    transform: rotate(-1deg);
  }
}

.textWrapper {
  @include h.responsive-variations(
    font-size,
    (
      s: h.rem(18px),
      m: h.rem(14px),
    )
  );
  @include h.responsive-variations(
    margin,
    (
      s: 0 0 0 h.rem(15px),
      m: h.rem(15px) 0 0,
      x: 0 0 0 h.rem(15px),
    )
  );
  font-family: var(--secondary-font);
  line-height: 1.5;
  color: var(--grey-70);

  &.noImg {
    margin: 0;
  }

  .searchWordGame & {
    margin: h.rem(0 0 0 15px);
  }

  h3:hover {
    text-decoration: underline;
    text-decoration-color: var(--accent-blue);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
}

:global(.tease-row--edge-to-edge) {
  .teaseWrapper {
    @include h.responsive-variations(
      width,
      (
        s: h.rem(135px),
        m: h.rem(90px),
        l: h.rem(122px),
        x: h.rem(100px),
        xx: h.rem(130px),
      )
    );
    @include h.responsive-variations(
      flex-basis,
      (
        s: h.rem(135px),
        m: h.rem(90px),
        l: h.rem(122px),
        x: h.rem(100px),
        xx: h.rem(130px),
      )
    );
  }

  .textWrapper {
    @include h.responsive-variations(
      font-size,
      (
        s: h.rem(18px),
        m: h.rem(14px),
        xx: h.rem(16px),
      )
    );
    @include h.responsive-variations(
      margin,
      (
        s: 0 0 0 h.rem(15px),
        m: h.rem(15px) 0 0,
        x: 0 0 0 h.rem(15px),
        xx: 0 0 0 h.rem(20px),
      )
    );
  }

  .noImg {
    .textWrapper {
      @include h.responsive-variations(
        margin,
        (
          m: 0,
        )
      );
    }
  }
}

.teaseRowHeadline {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin: 0;
  font-size: 18px;

  @include h.breakpoint-m {
    font-size: 16px;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--accent-blue);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  .searchWordGame & {
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: var(--accent-orange);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
}

.halfWidth {
  .contentItem {
    @include h.responsive-variations(
      padding,
      (
        m: 0 8%,
        l: 0 13.5%,
        x: 0 6%,
      )
    );
  }

  @include h.breakpoint-m {
    .contentItem {
      flex-basis: 50%;
      width: 50%;

      &.firstInRow {
        padding-left: 0;
      }

      &.lastInRow {
        padding-right: 0;
      }
    }
  }
}

.fullWidth {
  .contentItem {
    @include h.responsive-variations(
      flex-basis,
      (
        m: 27%,
        l: 28.5%,
        x: 26.5%,
      )
    );
    @include h.responsive-variations(
      width,
      (
        m: 27%,
        l: 28.5%,
        x: 26.5%,
      )
    );
    @include h.responsive-variations(
      padding,
      (
        m: 0 4%,
        l: 0 7%,
        x: 0 2%,
      )
    );

    :global(.featureLead) & {
      @include h.breakpoint-x {
        padding: 0 3%;
      }
    }

    &.lastInRow,
    &.firstInRow {
      @include h.responsive-variations(
        flex-basis,
        (
          m: 23%,
          l: 21.5%,
          x: 23.5%,
        )
      );
      @include h.responsive-variations(
        width,
        (
          m: 23%,
          l: 21.5%,
          x: 23.5%,
        )
      );
    }

    &.firstInRow {
      padding-left: 0;
    }

    &.lastInRow {
      padding-right: 0;
    }
  }
}

.contentItem:has(.teaseWrapper a:hover, .textWrapper h3:hover) {
  > .teaseWrapper > a picture {
    transform: rotate(-1deg);
  }

  > .textWrapper h3 {
    text-decoration: underline;
    text-decoration-color: var(--accent-blue);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;

    .searchWordGame & {
      text-decoration-color: var(--accent-orange);
    }
  }
}

.rail,
.railAdjacent {
  @include h.breakpoint-m {
    .contentItem {
      flex-basis: 37%;
      width: 37%;
      padding: 0 5%;

      &.firstInRow {
        padding-left: 0;
      }

      &.lastInRow {
        padding-right: 0;
      }

      &.firstInRow,
      &.lastInRow {
        flex-basis: 31.5%;
        width: 31.5%;
      }
    }
  }
}

.railAdjacent {
  .teaseWrapper {
    @include h.responsive-variations(
      flex-basis,
      (
        m: h.rem(100px),
        l: h.rem(120px),
        x: h.rem(90px),
      )
    );
    @include h.responsive-variations(
      width,
      (
        m: h.rem(100px),
        l: h.rem(120px),
        x: h.rem(90px),
      )
    );
  }

  @include h.breakpoint-x {
    .contentItem {
      flex-basis: 35%;
      width: 35%;
      padding: 0 3%;

      &.firstInRow,
      &.lastInRow {
        flex-basis: 32.5%;
        width: 32.5%;
      }
    }
  }
}

@mixin rail-column-spacing {
  .wrapper {
    flex-direction: column;
  }

  .contentItem {
    padding: h.rem(18px) 0;
    margin-bottom: 0;
    position: relative;
    width: 100%;

    &::after {
      content: '';
      width: 70%;
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      border-bottom: solid 1px var(--grey-30);
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;

      &::after {
        border-bottom: none;
      }
    }

    &.firstInRow,
    &.lastInRow {
      width: 100%;
    }
  }
}

.rail {
  :global(.grid-fr-quarter-xl) & {
    @include h.breakpoint-x {
      @include rail-column-spacing;
    }
  }

  :global(.grid-col-4-l) & {
    @include h.breakpoint-l {
      @include rail-column-spacing;
    }
  }

  @include h.breakpoint-m-only {
    &.singleItem .contentItem {
      flex-direction: row;
      width: 100%;
      flex-basis: 100%;

      .textWrapper {
        margin: 0 0 0 h.rem(15px);
      }
    }
  }
}
