@use 'assets/styles/utils/helper' as h;

.timestamp {
  --ts--display: block;
  --ts--font-family: var(--founders-mono);
  --ts--font-weight: var(--normal);
  --ts--font-size: #{h.rem(12px)};
  --ts--margin-right: 0;
  --ts--text-color: var(--grey-70);

  :global(body.select) & {
    --ts--font-family: var(--lato);
  }

  :global(body.today) & {
    --ts--display: inline;
    --ts--font-family: var(--secondary-font);
    --ts--font-size: 14px;
    --ts--margin-right: 10px;
    --ts--text-color: var(--grey-60);
  }
}

.chatStyleTimeStamp {
  --chatStyle-ts--font-family: var(--oswald-font-family);
  --chatStyle-ts--font-size: #{h.rem(13px)};
  --chatStyle-ts--line-height: 120%;
  --chatyStyle-ts--letter-spacing: -0.5px;
  --chatyStyle-ts--font-weight: 400;
}
