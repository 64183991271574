@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.tease-card__picture {
  position: relative;
}

.tease-card__picture-link {
  position: relative;
  display: block;
}

.tease-card__content {
  position: relative;
  z-index: 1;
  display: block;

  @include h.breakpoint-m {
    width: 100%;
    display: inline-block;
  }

  &.tease-card__title--is-paid-content {
    background: var(--grey-70);
    padding-bottom: 0;
    // This is getting overrides on a package level
    // We need an exception for sponsored content
    margin-bottom: h.rem(48px) !important;
  }

  &.tease-card--no-main-image {
    height: var(--tease-card--no-main-image-height);
    background-color: transparent;

    @include h.breakpoint-m {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    & .tease-card__picture-link {
      position: absolute;
      z-index: 2;
      background-color: transparent;
    }
  }
}

.tease-card__content--inner {
  display: var(--tease-card-content-inner-display);
}

.tease-card--tease-row-related {
  margin-top: var(--tease-card-tease-row-related-margin-top);
  padding-top: 20px;
  border-top: 1px solid var(--grey-40);
}

.tease-card__info {
  background-color: var(--tease-card-info-background-color);
  position: relative;
  display: block;
  width: 100%;
  padding: var(--tease-card-info-padding);

  @include h.breakpoint-m {
    width: 100%;
  }

  &.tease-card__info--is-paid-content:not(.bg-knockout-primary) {
    background: var(--grey-70);
  }

  .tease-card--no-main-image & {
    background: var(--tease-card-no-art-background-color);
    flex: 1 0 auto;
    justify-content: center;
    line-height: var(--tease-card-no-art-line-height);
    margin: var(--tease-card-no-image-margin);
    min-height: h.rem(200px);
    padding: var(--tease-card-no-image-padding);
    position: relative;
    width: calc(100% - (2 * var(--tease-card-outer-gutter)));

    @include h.breakpoint-m {
      width: calc(100% - var(--tease-card-outer-gutter));
      padding-right: h.rem(19px);
    }

    &::after {
      border: 1px solid var(--grey-40);
      position: absolute;
      height: 100%;
      width: 100%;
      top: -20px;
      left: -20px;
      display: block;
      content: ' ';
      pointer-events: none;
    }
  }

  .tease-card__has-featured-author.tease-card--no-main-image & {
    padding-bottom: h.rem(19px);
  }
}

.tease-card__featured-author {
  font-family: var(--tease-card-featured-author-font-family);

  @include h.responsive-variations(line-height, t.$tease-card-featured-author-line-heights);
  font-weight: normal;
  color: var(--tease-card-featured-author-color);
  font-size: var(--tease-card-featured-author-font-size);
}

.tease-card__title {
  @include h.responsive-variations(line-height, t.$tease-card-title-line-heights);
  color: var(--tease-card-title-color);
  font-family: var(--tease-card-title-font-family);
  font-size: var(--tease-card-title-font-size);
  font-weight: var(--tease-card-title-font-weight);
  flex-basis: 50%;
  flex-grow: 1;
  margin: 0;

  .tease-card--no-main-image & {
    flex-basis: auto;
    flex-grow: 0;
    margin-right: h.rem(20px);
    padding-top: h.rem(40px);

    @include h.breakpoint-m {
      padding-top: h.rem(46px);
    }

    @include h.breakpoint-x {
      padding-top: h.rem(68px);
    }
  }

  .tease-card__headline {
    color: var(--tease-card-title-headline-color);
    vertical-align: middle;

    &.tease-card__headline--is-paid-content {
      color: var(--white);
    }

    &--no-art {
      line-height: var(--tease-card-art-title-line-height);
    }

    .tease-card__headline--deepLink-episode {
      font-family: var(--publico-hed);
      font-size: h.rem(20px);
      line-height: var(--leading-125);
      font-weight: var(--light);
      color: var(--tease-card-title-deeplink-episode-color);

      @include h.breakpoint-m {
        font-size: h.rem(32px);
        line-height: var(--leading-100);
      }

      @include h.breakpoint-l {
        font-size: h.rem(24px);
        line-height: var(--leading-125);
      }

      @include h.breakpoint-x {
        font-size: h.rem(32px);
        line-height: var(--leading-100);
      }
    }
  }

  .tease-card__title--is-paid-content & {
    .tease-card__headline {
      color: var(--white);
    }

    .tease-card__paid-sponsor {
      margin-top: h.rem(10px);
      margin-bottom: 0;
      padding-bottom: 1.25rem;
    }
  }
}

.tease-card__sponsor-name {
  font-family: var(--tease-card--sponsor-name-font-family);
  font-size: 1rem;
  line-height: 1;
  color: var(--grey-50);
  text-align: left;
  letter-spacing: normal;
}

.tease-card__title:hover {
  opacity: var(--tease-card-title--hover--opacity);
}

.tease-card__dek {
  .tease-card--no-main-image &,
  .tease-card__has-featured-author & {
    flex-basis: auto;
    flex-grow: 0;
  }

  .tease-card__title--is-paid-content & {
    color: var(--grey-30);
  }
}

.tease-card__live {
  display: flex;
  vertical-align: center;
  flex-basis: 100%;
}

.tease-card__live-icon {
  svg {
    padding: h.rem(0.5 0.5 0.5 0.5);
  }
  float: left;
  clear: both;
}

.tease-card__live-text {
  color: var(--white);

  @include h.breakpoint-m {
    color: var(--black);
  }

  @include h.breakpoint-l {
    color: var(--white);
  }
  font-family: var(--tease-card-live-text-font-family);
  font-size: h.rem(14px);
  letter-spacing: h.rem(0.5px);
  font-weight: bold;
  line-height: 1.17;
  float: left;
  clear: both;
  padding-top: h.rem(4px);
  margin-left: h.rem(10px);
}

.tease-card__byline {
  font-family: var(--tease-card-byline-font-family);
  margin: var(--tease-card-byline-margin);
  flex-basis: 100%;
  font-size: var(--tease-card-byline-font-size);
  line-height: var(--tease-card-byline-line-height);
  letter-spacing: var(--tease-card-byline-letter-spacing);
  color: var(--tease-card-byline-color);

  .tease-card--no-main-image & {
    flex-basis: auto;
  }
}

.tease-card__author {
  color: var(--black);
  text-transform: var(--tease-card-author-text-transform);
  font-weight: var(--tease-card-author-font-weight);

  .tease-card__title--is-paid-content & {
    color: var(--grey-30);
  }

  a:hover {
    opacity: var(--opacity-70);
  }
}

.tease-card__date {
  &::before {
    content: ' ';
  }

  &:nth-child(2) {
    &::before {
      .tease-card__title--is-paid-content & {
        color: var(--grey-30);
      }
    }
  }
}

.tease-card__featured-author-image-container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tease-card__featured-author-image {
  flex-shrink: 0;

  img {
    display: block;
    height: h.rem(110px);
    filter: grayscale(100%);
    width: auto;

    @include h.breakpoint-m {
      max-width: 100%;
    }
  }
}

.tease-card__quote {
  color: var(--tease-card-quote-color);
  display: block;
  margin: h.rem(20px 0);
}

.tease-card__paid-content-sponsor {
  font-family: var(--tease-card-paid-content-sponsor-font-family);
  color: var(--grey-50);
  display: inherit;
  font-size: 12px;
  line-height: 12px;
  padding-bottom: 20px;
  padding-top: 8px;
}

// Paid content tease
.tease-card__content .ad {
  h2.plr_article:not(.unibrow) {
    @include h.responsive-variations(
      font-size,
      (
        s: h.rem(24px),
        m: h.rem(40px),
        l: h.rem(30px),
        x: h.rem(40px),
      )
    );
    @include h.responsive-variations(line-height, t.$tease-card-title-line-heights);
    color: var(--tease-card-title-color);
    font-family: var(--tease-card-title-font-family);
    font-weight: normal;
    margin: 0;

    .today &,
    .think & {
      @include h.responsive-variations(
        font-size,
        (
          s: h.rem(18px),
          m: h.rem(30px),
          l: h.rem(22.5px),
          x: h.rem(30px),
        )
      );
    }

    .select & {
      @include h.responsive-variations(
        font-size,
        (
          s: h.rem(22px),
          m: h.rem(24px),
          l: h.rem(22px),
          x: h.rem(24px),
        )
      );
    }
  }
}

.tease-card__deepLink--season-date {
  .tease-card__deepLink--season {
    text-transform: uppercase;
  }
}

body.today {
  .tease-card--no-main-image {
    .tease-card__info {
      &::after {
        border: 0;
      }

      .tease-card__headline {
        padding-top: 0;
      }
    }
  }

  .tease-card__picture a:hover {
    transform: var(--tease-card-picture--hover-transform);
  }

  .tease-card__headline a:hover {
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: var(--accent-blue);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  .tease-card__content--today:has(.tease-card__headline a:hover, .tease-card__picture a:hover) {
    .tease-card__picture a {
      transform: var(--tease-card-picture--hover-transform);
    }

    .tease-card__headline a {
      display: inline-block;
      text-decoration: underline;
      text-decoration-color: var(--accent-blue);
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    }
  }
}
